const goalTypes = [
    {
        key: 'carry_rescue_inhaler',
        name: 'Carry Rescue Inhaler',
        description: 'Remember to carry rescue inhaler when away from home',
        barrier_question: 'What might stop you from carrying your rescue inhaler?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: 'Eg: Keep a go-bag somewhere you are sure to remember, like the kitchen or where you keep your school bag',
    },
    {
        key: 'daily_medication_goal',
        name: 'Daily Medication Goal',
        description: 'Take daily medication',
        barrier_question: 'What might stop you from taking your daily medication?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: 'Eg: Keep by toothbrush or somewhere you are sure to see it',

    },
    {
        key: 'inhaler_before_exercise',
        name: 'Inhaler Before Exercise',
        description: 'Take rescue medication before exercise (or other trigger)',
        barrier_question: 'What might stop you from carrying your rescue inhaler?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: 'Eg: Keep inhaler in sports bag. Or tell coach ahead of time.',
    },
    {
        key: 'medicine_running_out',
        name: 'Medicine Running Out',
        description: 'Report when medicine is running low',
        barrier_question: 'What might stop you from reporting when medicines running out?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: 'Eg: Remember to check inhaler before going out for activities at least once a week.',
    },
    {
        key: 'medicine_when_symptoms',
        name: 'Medicine When Symptoms',
        description: 'Take rescue medication when you have symptoms',
        barrier_question: 'What might stop you from carrying your rescue inhaler?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: 'Eg: Keep a go-bag somewhere you are sure to remember, like the kitchen or where you keep your school bag',
    },
    {
        key: 'write_own',
        name: 'Write Own',
        description: 'Write my own goal',
        barrier_question: 'What might stop you from achieving your goal?',
        strategy_question: 'What strategies will you use to prevent these barriers?',
        strategy_helper_text: null,
    },
]

export default goalTypes