import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '../components/library'
import goalCheckInMarks from '../utils/goalCheckInMarks'
import getColorFromLevel from '../utils/getColorFromLevel'

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: 0,
            '& small': {
                opacity: 0.65
            }
        }
    },
    icon: {
        fontSize: 25,
        lineHeight: 1,
        verticalAlign: 'middle'
    }
}))


function GoalDescription({goal}) {
    const classes = useStyles()
    if(typeof goal.attributes === 'undefined') return <></>

    const check_in_mark = typeof goal.attributes.field_check_in_value !== 'undefined' ? goalCheckInMarks.find(mark => mark.value === parseInt(goal.attributes.field_check_in_value)) : null
    const check_in_level = check_in_mark !== null && typeof check_in_mark !== 'undefined' ? check_in_mark.level : 0;

    let icon;
    switch (check_in_level) {
        case 0:
            icon = 'bad'
            break;
        case 4:
            icon = 'good'
            break;
        default:
            icon = 'neutral'
            break;
    }

    return (
        <div className={classes.root}>
            {
                ['am', 'pm'].map((ampm) => {
                return goal.attributes[`field_${ampm}_take_frequency`] &&
                    goal.attributes[`field_${ampm}_frequency_check`] &&
                    goal.attributes[`field_${ampm}_frequency_time`] && (
                        <Typography xs={9} key={`${ampm}-${goal.local_id}`}>
                            - Remember to take {ampm.toUpperCase()} medication {goal.attributes[`field_${ampm}_take_frequency`]} times a week at {goal.attributes[`field_${ampm}_frequency_time`].charAt(0) !== '0' ? goal.attributes[`field_${ampm}_frequency_time`] : goal.attributes[`field_${ampm}_frequency_time`].substr(1)}{ampm.toUpperCase()}
                        </Typography>
                    ) 
                })
            }
            {goal.type === 'node--asthma_check_in_goal' && (
                <Typography variant="body1" align="left">Check in with parent about asthma symptoms {goal.attributes.field_check_in_frequency} {parseInt(goal.attributes.field_check_in_frequency) === 1 ? 'time' : 'times'} this week.</Typography>
            )}
            {goal.attributes.field_goal_description && (
                <Typography variant="body1" align="left"><small>Description:</small> {goal.attributes.field_goal_description}</Typography>
            )}
            {goal.attributes.field_parent_stopping && (
                <Typography variant="body1" align="left"><small>Parent stopping:</small> {goal.attributes.field_parent_stopping}</Typography>
            )}
            {goal.attributes.field_child_stopping && (
                <Typography variant="body1" align="left"><small>Child stopping:</small> {goal.attributes.field_child_stopping}</Typography>
            )}
            {goal.attributes.field_strategies && (
                <Typography variant="body1" align="left"><small>Strategies:</small> {goal.attributes.field_strategies}</Typography>
            )}
            {goal.attributes.field_goal_name && goal.attributes.field_goal_name.length > 0 && (
                <Typography variant="body1" align="left">{goal.attributes.field_goal_name}</Typography>
            )}
            {typeof check_in_mark !== 'undefined' && check_in_mark !== null && (
                <Typography variant="body1" align="left"><small>How did it go?</small> <i className={`icon-mood-${icon} ${classes.icon}`} style={{color: getColorFromLevel(check_in_level)}} /> {check_in_mark.label}</Typography>
            )}

        </div>
    )
}

export default GoalDescription
