import goalTypes from "./goalTypes"

const getGoalTitle = (goal, ignore_name) => {
  if(goal.type === 'node--asthma_check_in_goal'){
    return goal?.attributes?.title ? goal.attributes.title : 'My Goal'
  }
  const goalType = goal?.attributes?.field_goal_type ? goalTypes.find(type => type.key === goal.attributes.field_goal_type) : null
  if(!goalType) return 'My Goal'
  
  if(goalType.key === 'write_own' && ignore_name){
    const max_length = 40
    return goal.attributes.field_goal_description.length > max_length ? `${goal.attributes.field_goal_description.substring(0,4)} [...]` : goal.attributes.field_goal_description
  }else{
    return goal?.attributes?.title ? goal.attributes.title : 'My Goal'
  }
}
export default getGoalTitle