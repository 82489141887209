import React from 'react'
import { makeStyles } from '@material-ui/styles'
import hexToRgbA from '../utils/hexToRgbA'
import { ImpactContext } from './Layout'
import { CircularProgress, Alert, Icon, Typography } from './library'
import Goal from './Goal'

const useStyles = makeStyles((theme) => ({
    list: {
        '& > *': {
            marginTop: theme.spacing(3)
        }
    }
}))




function Goals({week, edit, backTo = '/'}) {
    const classes = useStyles()
    const { user, userData, weeks, today, currentWeek, isPastWeek } = React.useContext(ImpactContext)
    if(user === null || userData === null || week === null || userData === null || week === null) {
        return <CircularProgress />
    }

    return (
        <div>
            {week.goals !== null && (
                <div className={classes.list}>
                    {
                        (
                            (
                                currentWeek.interval.e > today && 
                                today < week.interval.s &&
                                !currentWeek.todo_done &&
                                today > weeks[0].interval.s
                            ) || (
                                today < weeks[0].interval.s && 
                                week.number > 0 &&
                                !currentWeek.todo_done
                            ) || (
                                today < weeks[0].interval.s && 
                                currentWeek.todo_done &&
                                week.number > currentWeek.number + 1
                            ) || (
                                currentWeek.todo_done &&
                                week.number > currentWeek.number + 1
                            ) || (
                                isPastWeek &&
                                today < week.interval.e
                            )
                        ) && (
                            <Alert
                                title="You'll be able to add goals later"
                                icon={<Icon name="none" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
                                alignMiddle
                                color="secondary"
                            />
                        )
                    }
                    {week.goals.length > 0 && (
                        <>
                            {week.goals_are_all_checked_in && (
                                <Alert
                                    title="All goals checked in for this week"
                                    icon={<Icon name="ok" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
                                    alignMiddle
                                    color="secondary"
                                />
                            )}
                            {week.goals_not_checked_in.length > 0 && week.goals_not_checked_in.map((uuid) => <Goal goal={userData[uuid]} uuid={uuid} key={uuid} edit={edit} backTo={backTo} />)}
                            {week.goals_checked_in.length > 0 && (
                                <>
                                    
                                    {week.goals_not_checked_in.length > 0 && <Typography variant="subtitle1" shade="dark" className={classes.subtitle}>{`Checked in goals:`}</Typography>}
                                    {week.goals_checked_in.map((uuid) => <Goal goal={userData[uuid]} uuid={uuid} key={uuid} complete backTo={backTo} />)}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}

        </div>
    )
}

export default Goals
