import React from 'react'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Container, TextField } from '@material-ui/core'
import { ImpactContext } from '../components/Layout'
import { Spacer, Typography, CircularProgress, Pet, Button } from '../components/library'
import Goals from '../components/Goals'
import { UserContext } from '@parallelpublicworks/entitysync'

const useStyles = makeStyles(theme => ({
  weekSelectorRow: {
    display: 'flex',
    // alignItems: 'center',
    '& .MuiButton-root': {
      maxWidth: 240,
      marginLeft: theme.spacing(2)
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 30,
      overflow: 'hidden'
    },
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(3)
    }
  },
  goalsList: {
    '& > *': {
      marginTop: theme.spacing(3)
    }
  }
}))

function GoalsPage() {
  const classes = useStyles()
  const { user, setUser, profiles, weeks, currentWeek, isPastWeek, petLevel, bonesDialogOpen, closeBonesDialog } = React.useContext(
    ImpactContext
  )
  const userContext = React.useContext(UserContext)
  const userState = userContext[0]
  const dispatch = userContext[1]
  const [selectedWeek, setSelectedWeek] = React.useState(null)
  const [petLevelPadding, setPetLevelPadding] = React.useState(3)

  React.useEffect(() => {
    if (currentWeek !== null) {
      if (currentWeek.todo_done && typeof weeks[currentWeek.number + 1] !== 'undefined') {
        setSelectedWeek(weeks[currentWeek.number + 1])
      } else {
        setSelectedWeek(currentWeek)
      }
    }
    if (bonesDialogOpen) {
      closeBonesDialog()
    }
  }, [currentWeek])

  React.useEffect(() => {
    let padding
    switch (petLevel) {
      case 5:
        padding = 10.5
        break
      case 2:
        padding = 5.5
        break
      case 3:
        padding = 7.5
        break
      case 4:
        padding = 8.5
        break
      default:
        padding = 2
        break
    }
    setPetLevelPadding(padding)
  }, [petLevel])

  if (profiles === null || weeks === null || currentWeek === null || selectedWeek === null) return <CircularProgress />

  return (
    <Container maxWidth="md">
      <Spacer amount={petLevelPadding * 1.25} />
      <Typography variant="h1" shade="dark">
        My Goals
      </Typography>
      <Spacer amount={petLevelPadding * 0.75} />
      <Pet horizontal>
        <Typography>
          Help {profiles.pet.name.length > 0 ? profiles.pet.name : 'me'} grow! Pick goals for this week and keep checking in
        </Typography>
      </Pet>
      <Spacer />
      <div className={classes.weekSelectorRow}>
        {selectedWeek !== null && (
          <TextField
            id="select-week"
            value={selectedWeek.number}
            select
            variant="outlined"
            onChange={e => {
              const val = e.target.value
              setSelectedWeek(weeks[val])
            }}
            SelectProps={{
              native: true
            }}
          >
            {weeks.map(week => (
              <option value={week.number} key={`week${week.number}`}>
                {week.label}
              </option>
            ))}
          </TextField>
        )}
        {((selectedWeek.number === currentWeek.number && !isPastWeek && !currentWeek.todo_done) ||
          (currentWeek.todo_done && selectedWeek.number === currentWeek.number + 1)) && (
          <Button
            onClick={e => {
              navigate('/goal-form', { state: { backTo: '/goals' } })
            }}
            variant="contained"
            color="primary"
          >
            Add Goal
          </Button>
        )}
      </div>
      <Goals week={selectedWeek} edit={selectedWeek.number === currentWeek.number} />
    </Container>
  )
}

export default GoalsPage
